<template>
  <div class="list-container p-12">
    <div class="flex justify-between items-center mb-6">
      <p class="text-xl">Manage journeys</p>
      <div class="add-btn" @click="add">Create a new journey</div>
    </div>
    <div class="journey-wrapper">
      <div
        class="journey-item flex mb-4 relative"
        v-for="(item, index) in tableData"
        :key="index"
      >
        <div>
          <img v-if="item.cover_pic" class="cover mr-6" :src="item.cover_pic" />
          <div
            v-else
            class="cover mr-6 flex items-center justify-center"
            style="background-color: #f8f8f8"
          >
            <img
              src="../../assets/empty-cover.png"
              style="width: 114px; height: 38px"
            />
          </div>
        </div>
        <div class="flex-1 flex flex-col">
          <p class="name text-xl mb-1">{{ item.name }}</p>
          <p class="intro mb-2 flex-1">{{ item.intro }}</p>
          <div class="flex text-sm gap-12">
            <p>
              Engagements:<span class="ml-3" style="color: #27a6f9">{{
                item.engagements_num
              }}</span>
            </p>
            <p>
              Customers onboard:<span class="ml-3" style="color: #27a6f9">{{
                item.onboard_count
              }}</span>
            </p>
          </div>
        </div>
        <i class="el-icon-edit absolute edit-icon" @click="edit(item)"></i>
        <i class="el-icon-delete absolute delete-icon" @click="del(item)"></i>
      </div>
    </div>
    <el-dialog :visible.sync="show" :close-on-click-modal="false">
      <el-form :model="form">
        <el-form-item label="Name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="Introduction">
          <el-input v-model="form.intro" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submit">Submit</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { getBooks, updateBook, removeBook } from "../../api/index.js";
import { getUserId } from "../../utils/store.js";
export default {
  data() {
    return {
      tableData: [],
      form: {},
      show: false,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {

      getBooks({"creater_id":getUserId()}).then((res) => {
        this.tableData = res.data.data;
      });
    },
    add() {
      this.form = {
        name: "",
        intro: "",
        id: "",
      };
      this.show = true;
    },
    submit() {
      updateBook({
        creater_id: getUserId(),
        id: this.form.id,
        name: this.form.name,
        intro: this.form.intro,
        open_status: true,
        secrecy: "all",
        cover_pic: "",
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("操作成功");
          this.fetchData();
          this.show = false;
        }
      });
    },
    edit(row) {
      this.$router.push({
        path: "/journeyDetail",
        query: {
          bookId: row._id.$id,
        },
      });
    },
    del(row) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeBook({
            id: row._id.$id,
          }).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("删除成功");
              this.fetchData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-container {
  font-family: "Lato";
  .add-btn {
    padding: 10px;
    cursor: pointer;
    color: #fefefe;
    font-size: 16px;
    border-radius: 4px;
    background: #27a6f9;
  }
  .journey-wrapper {
    .journey-item {
      border-radius: 4px;
      background: #fefefe;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 12px;
      cursor: pointer;
      &:hover {
        .edit-icon {
          opacity: 1;
        }
        .delete-icon {
          opacity: 1;
        }
      }
      .cover {
        width: 150px;
        height: 100px;
        border-radius: 4px;
        background: #ddd;
        object-fit: cover;
      }
      .intro {
        color: #666;
        font-size: 14px;
      }
      .edit-icon {
        font-size: 24px;
        color: #27a6f9;
        right: 12px;
        bottom: 12px;
        opacity: 0;
      }
      .delete-icon {
        font-size: 24px;
        color: #999;
        right: 48px;
        bottom: 12px;
        opacity: 0;
      }
    }
  }
}
</style>
