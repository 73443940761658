<template>
  <div class="l-c">
    <el-form class="login-container" label-width="80px">
      <h3 class="title">Journei</h3>
      <el-form-item label="账号 ">
        <el-input
          type="text"
          style="width: 90%"
          v-model="ruleForm2.account"
          auto-complete="off"
          placeholder="账号"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码 ">
        <el-input
          style="width: 90%"
          v-model="ruleForm2.pass"
          auto-complete="off"
          placeholder="密码"
          type="password"
        ></el-input>
      </el-form-item>
      <el-form-item label="类型 ">
        <el-select
          style="width: 90%"
          v-model="ruleForm2.type"
          auto-complete="off"
          placeholder="密码"
          type="password"
        >
          <el-option value="创作者"></el-option>
          <el-option value="用户"></el-option>
        </el-select>
      </el-form-item>
      <span v-if="!!error_text" style="color: red" class="error_text">{{
        error_text
      }}</span>
      <div style="text-align: center; margin-top: 20px; margin-bottom: 20px">
        <el-button
          type="primary"
          style="width: 78%"
          @click="submit"
          :loading="logining"
          >登录</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import { setUserId, setUserName, setUserType } from "../../utils/store";
import { loginByCreater, loginByUser } from "../../api/";
// contact@eae.online
export default {
  name: "Login",
  data() {
    return {
      error_text: "",
      logining: false,
      admin: false,
      ruleForm2: {
        account: "",
        pass: "",
        type: "创作者",
      },
    };
  },
  methods: {
    submit() {
      if (this.ruleForm2.account === "" || this.ruleForm2.account === "") {
        this.$message({
          message: "某些表单没有填写请填写后提交~",
          type: "error",
          duration: 1500,
        });
        return;
      }

      this.logining = true;
      if (this.ruleForm2.type === "创作者") {
        loginByCreater({
          creater_email: this.ruleForm2.account,
          creater_pwd: this.ruleForm2.pass,
        }).then((res) => {
          this.logining = false;
          if (res.data.code === 0) {
            this.$message({
              message: "登录成功",
              type: "success",
              duration: 1500,
            });
            setUserId(res.data.data._id.$id);
            setUserName(res.data.data.creater_name);
            setUserType("creater");
            this.$router.push("/journeiList");
          } else {
            this.logining = false;
            this.$message({
              message: res.data.msg,
              type: "error",
              duration: 1500,
            });
          }
        });
      } else {
        loginByUser({
          user_email: this.ruleForm2.account,
          user_pwd: this.ruleForm2.pass,
        }).then((res) => {
          this.logining = false;
          if (res.data.code === 0) {
            this.$message({
              message: "登录成功",
              type: "success",
              duration: 1500,
            });
            setUserId(res.data.data._id.$id);
            setUserName(res.data.data.user_name);
            setUserType("user");
            this.$router.push("/user/book");
          } else {
            this.logining = false;
            this.$message({
              message: res.data.msg,
              type: "error",
              duration: 1500,
            });
          }
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.l-c {
  width: 100%;
  height: 100vh;
  position: relative;
  background: #fff;
  background-position: 0 0;
  .login-container {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    background-clip: padding-box;
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 360px;
    padding: 35px 35px 15px 35px;
    background: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
    .title {
      margin: 0px auto 10px auto;
      text-align: center;
      color: #505458;
    }
    .remember {
      margin: 0px 0px 35px 0px;
    }
    .el-radio {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}
.error_text {
  color: red;
  // margin-bottom: 10px;
}
.forgetPwd {
  text-align: right;
  font-size: 12px;
  color: #409eff;
  cursor: pointer;
}
.forgetPwd:hover {
  opacity: 0.8;
}
</style>
