import request from "../utils/request";

export function getQiniuToken(param) {
    return request.post("./index.php", {
        function: "getQiniuToken",
        ...param,
    });
}

export function loginByCreater(param) {
    return request.post("./index.php", {
        function: "loginByCreater",
        ...param,
    });
}

export function loginByUser(param) {
    return request.post("./index.php", {
        function: "loginByUser",
        ...param,
    });
}

export function updateBook(param) {
    return request.post("./index.php", {
        function: "updateBook",
        ...param,
    });
}
export function saveBookSenderEmail(param) {
    return request.post("./index.php", {
        function: "saveBookSenderEmail",
        ...param,
    });
}


export function getBooks(param) {
    return request.post("./index.php", {
        function: "getBooks",
        ...param,
    });
}

export function removeBook(param) {
    return request.post("./index.php", {
        function: "removeBook",
        ...param,
    });
}

export function updateBookContent(param) {
    return request.post("./index.php", {
        function: "updateBookContent",
        ...param,
    });
}

export function getBookContents(param) {
    return request.post("./index.php", {
        function: "getBookContents",
        ...param,
    });
}

export function removeBookContent(param) {
    return request.post("./index.php", {
        function: "removeBookContent",
        ...param,
    });
}

export function getBook(param) {
    return request.post("./index.php", {
        function: "getBook",
        ...param,
    });
}

export function getBookContent(param) {
    return request.post("./index.php", {
        function: "getBookContent",
        ...param,
    });
}

export function createLoginPinCode(params) {
    return request.post("./index.php", {
        function: "createLoginPinCode",
        ...params,
    });
}

export function verifyLogin(params) {
    return request.post("./index.php", {
        function: "verifyLogin",
        ...params,
    });
}

export function updateUserInfo(params) {
    return request.post("./index.php", {
        function: "updateCreaterInfo",
        ...params,
    });
}

export function updateVisitorInfo(params) {
    return request.post("./index.php", {
        function: "updateUserInfo",
        ...params,
    });
}

export function getBookUser(params) {
    return request.post("./index.php", {
        function: "getBookUser",
        ...params,
    });
}

export function getBookContentsUser(params) {
    return request.post("./index.php", {
        function: "getBookContentsUser",
        ...params,
    });
}

export function confirmOnboard(params) {
    return request.post("./index.php", {
        function: "confirmOnboard",
        ...params,
    });
}

export function batchOnboardByEmails(user_emails, book_id) {
    return request.post("./index.php", {
        function: "batchOnboardByEmails",
        user_emails,
        book_id
    });
}

export function getBookOnboardUsers(book_id) {
    return request.post("./index.php", {
        function: "getBookOnboardUsers",
        book_id
    });
}