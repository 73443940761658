<template>
  <header class="header py-3 px-6 flex items-center justify-between shadow-lg">
    <div class="flex items-center">
      <img class="mr-8 logo" src="../../assets/Logo.png" />
    </div>
    <el-dropdown>
      <div class="flex">
        <div class="text-right mr-3">
          <p class="text-base">{{ getUserName() }}</p>
          <p class="text-xs" style="color: #666">
            {{
              userType === "creater" ? "Journey Creator" : "Journey Traveler"
            }}
          </p>
        </div>
        <el-avatar :src="avatar"></el-avatar>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <div class="flex items-center" @click="editUserInfo">
            <i class="el-icon-user mr-2"></i>
            <p>User Profile</p>
          </div>
        </el-dropdown-item>
        <el-dropdown-item>
          <div class="flex items-center" @click="logout">
            <i class="el-icon-switch-button mr-2"></i>
            <p>Logout</p>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dialog title="User Profile" :visible.sync="userInfoVisible">
      <el-form :model="form" label-position="top">
        <el-form-item label="Name:" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Signature:" :label-width="formLabelWidth">
          <el-input v-model="form.signature" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Avatar:" :label-width="formLabelWidth">
          <el-upload
            class="avatar-uploader flex items-center justify-center"
            action="#"
            :limit="1"
            :before-upload="beforeAttachUpload"
            :show-file-list="false"
          >
            <img v-if="avatar" :src="avatar" class="avatar" />
            <div v-else>
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="userInfoVisible = false">Cancel</el-button>
        <el-button type="primary" @click="onUpdateUserInfo">Confirm</el-button>
      </div>
    </el-dialog>
  </header>
</template>

<script>
import { updateUserInfo, getQiniuToken, updateVisitorInfo } from "../../api";
import {
  getUserName,
  getUserType,
  removeUserId,
  removeUserEmail,
  removeUserName,
  getUserEmail,
  getUserId,
  getUserAvatar,
  setUserAvatar,
  setUserName,
  getUserSignature,
  setUserSignature,
  removeUserSignature,
  removeUserAvatar,
} from "../../utils/store";
import * as qiniu from "qiniu-js";
export default {
  data() {
    return {
      formLabelWidth: 200,
      name: getUserName(),
      userType: getUserType(),
      userInfoVisible: false,
      form: {
        name: getUserName(),
        signature: getUserSignature() || "",
      },
      avatar: getUserAvatar() || "",
    };
  },
  methods: {
    getUserEmail,
    getUserName,
    getUserAvatar,
    editUserInfo() {
      this.userInfoVisible = true;
    },
    logout() {
      this.$confirm("Are you sure you want to logout?", "Pay attention", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then((res) => {
        removeUserId();
        removeUserEmail();
        removeUserName();
        removeUserAvatar();
        removeUserSignature();
        location.reload();
      });
    },
    async onUpdateUserInfo() {
      try {
        const res =
          this.userType === "creater"
            ? await updateUserInfo({
                creater_id: getUserId(),
                creater_name: this.form.name,
                creater_avatar: this.avatar,
                creater_signature: this.form.signature,
              })
            : await updateVisitorInfo({
                user_id: getUserId(),
                user_name: this.form.name,
                user_avatar: this.avatar,
                user_signature: this.form.signature,
              });
        if (res.data.code === 0) {
          this.$message.success("Update successfully.");
          this.userInfoVisible = false;
          setUserName(
            this.userType === "creater"
              ? res.data.data.creater_name
              : res.data.data.user_name
          );
          setUserSignature(
            this.userType === "creater"
              ? res.data.data.creater_signature
              : res.data.data.user_signature
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async beforeAttachUpload(file) {
      const result = await this.getQiniuToken();
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        );
        const observer = {
          complete: async (res) => {
            const url = "http://mainbucket.reachable-edu.com/" + res.key;
            try {
              const res =
                this.userType === "creater"
                  ? await updateUserInfo({
                      creater_id: getUserId(),
                      creater_name: this.form.name,
                      creater_avatar: url,
                      creater_signature: this.form.signature,
                    })
                  : await updateVisitorInfo({
                      user_id: getUserId(),
                      user_name: this.form.name,
                      user_avatar: this.avatar,
                      user_signature: this.form.signature,
                    });
              if (res.data.code === 0) {
                setUserAvatar(url);
                this.avatar = url;
                setUserName(
                  this.userType === "creater"
                    ? res.data.data.creater_name
                    : res.data.data.user_name
                );
                setUserSignature(
                  this.userType === "creater"
                    ? res.data.data.creater_signature
                    : res.data.data.user_signature
                );
                this.$message.success("Update Avatar Successful.");
              }
            } catch (error) {
              console.log(error);
            }
          },
        };
        this.observable.subscribe(observer);
      }
    },
    async getQiniuToken() {
      return new Promise((resolve) => {
        getQiniuToken().then((res) => {
          resolve(res);
        });
      });
    },
  },
};
</script>

<style scoped>
.avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}
.avatar-uploader {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px dashed #27a6f9;
  &-icon {
    color: #27a6f9;
  }
  .tips {
    color: #27a6f9;
    width: 180px;
  }
}
.header {
  background: #fefefe;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  font-family: "Lato";
  font-size: 24px;
  .logo {
    width: 120px;
    height: 42px;
  }
}
.menu {
  font-size: 16px;
  width: 110px;
  cursor: pointer;
}
</style>
