import Vue from "vue";
import Router from "vue-router";
import { getUserId } from "../utils/store.js";
import Login from "../components/Login/index.vue";
import userBook from "../views/reader/bookList.vue";
import createrBook from "../views/creater/bookList.vue";
import createrContent from "../views/creater/contentList.vue";
import userContent from "../views/reader/contentList.vue";
import layout from "../views/layout.vue";
import createrContentDetail from "../views/creater/contentDetail.vue";
import userContentDetail from "../views/reader/contentDetail.vue";
import JourneiList from "../views/JourneiList";
import JourneyDetail from "../views/JourneyDetail";
import EngagementDetail from "../views/EngagementDetail";
import OPTVerify from "../views/OPTVerify";
import Home from '../views/Home';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      redirect: "/home",
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/home",
      name: "Home",
      component: Home,
    },
    {
      path: "/login",
      name: "Login",
      component: OPTVerify,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/optVerify",
      name: "OPTVerify",
      component: OPTVerify,
    },
    {
      path: "/layout",
      name: "layout",
      component: layout,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/user/book",
          name: "userBook",
          component: userBook,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/creater/book",
          name: "createrBook",
          component: createrBook,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/creater/content",
          name: "createrContent",
          component: createrContent,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/creater/contentDetail",
          name: "createrContentDetail",
          component: createrContentDetail,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/user/contentDetail",
          name: "userContentDetail",
          component: userContentDetail,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/user/content",
          name: "userContent",
          component: userContent,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/journeiList",
          name: "journeiList",
          component: JourneiList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/journeyDetail",
          name: "journeyDetail",
          component: JourneyDetail,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/engagementDetail",
          name: "engagementDetail",
          component: EngagementDetail,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    next();
  } else {
    if (to.meta.requiresAuth && !getUserId()) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  }
});
export default router;
