import Cookies from "js-cookie";

export function setUserId(user_id) {
  Cookies.set("pub_user_id", user_id);
}
export function getUserId() {
  return Cookies.get("pub_user_id");
}

export function setUserType(type) {
  Cookies.set("pub_user_type", type);
}
export function getUserType() {
  return Cookies.get("pub_user_type");
}

export function setUserName(user_name) {
  Cookies.set("pub_user_name", user_name);
}
export function getUserName() {
  return Cookies.get("pub_user_name");
}

export function removeUserId() {
  Cookies.remove("pub_user_id");
}

export function setUserEmail(user_email) {
  Cookies.set("pub_user_email", user_email);
}

export function getUserEmail() {
  return Cookies.get("pub_user_email");
}

export function setUserAvatar(avatar) {
  Cookies.set("pub_user_avatar", avatar);
}

export function getUserAvatar() {
  return Cookies.get("pub_user_avatar");
}

export function removeUserAvatar() {
  Cookies.remove("pub_user_avatar");
}

export function removeUserName() {
  Cookies.remove("pub_user_name");
}

export function removeUserEmail() {
  Cookies.remove("pub_user_email");
}

export function getUserSignature() {
  return Cookies.get("pub_user_signature");
}

export function setUserSignature(sign) {
  Cookies.set("pub_user_signature", sign);
}

export function removeUserSignature() {
  Cookies.remove("pub_user_signature");
}
