<template>
  <div class="createrBook">
    <el-button type="primary" @click="add">创建集子</el-button>
    <el-table :data="tableData" style="margin-top: 10px">
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="简介">
        <template slot-scope="scope">
          <div v-html="scope.row.intro"></div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="medium" @click="edit(scope.row)"
            >编辑</el-button
          >
          <el-button type="danger" size="medium" @click="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="show" :close-on-click-modal="false">
      <el-form :model="form">
        <el-form-item label="名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="简介">
          <quill-editor
            class="editor"
            style="margin-top: 40px; margin-bottom: 20px"
            v-model="form.intro"
            :options="editorOption"
          >
          </quill-editor>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submit">提交</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { getBooks, updateBook, removeBook } from "../../api/index.js";
import { getUserId } from "../../utils/store.js";
export default {
  data() {
    return {
      tableData: [],
      form: {},
      show: false,
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getBooks({"creater_id":getUserId()}).then((res) => {
        this.tableData = res.data.data;
      });
    },
    add() {
      this.form = {
        name: "",
        intro: "",
        id: "",
      };
      this.show = true;
    },
    submit() {
      updateBook({
        creater_id: getUserId(),
        id: this.form.id,
        name: this.form.name,
        intro: this.form.intro,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("操作成功");
          this.fetchData();
          this.show = false;
        }
      });
    },
    edit(row) {
      this.$router.push({
        path: "/creater/content",
        query: {
          bookId: row._id.$id,
        },
      });
    },
    del(row) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeBook({
            id: row._id.$id,
          }).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("删除成功");
              this.fetchData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.createrBook {
  width: 100%;
  padding: 16px;
}
</style>
<style>
.ql-container {
  height: 200px !important;
}
</style>