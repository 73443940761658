var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"top-container relative"},[_c('div',{staticClass:"flex p-12 justify-between z-10 relative"},[_vm._m(0),_c('div',{staticClass:"hover:underline cursor-pointer",on:{"click":_vm.goLogin}},[_vm._v(" Sign in ")])]),_c('div',{staticClass:"relative z-10 mt-12"},[_c('p',{staticClass:"text-center top-title"},[_vm._v("Create a better customer journey")]),_c('p',{staticClass:"text-center top-sub-title"},[_vm._v(" By using Journei's digital User Guide solution ")]),_c('div',{staticClass:"flex justify-center items-center mt-24 gap-12"},[_c('div',{staticClass:"btn start-btn",on:{"click":_vm.goLogin}},[_vm._v("Start")]),_c('div',{staticClass:"btn more-btn"},[_vm._v("Learn More")])])]),_vm._m(1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"top-logo",attrs:{"src":require("../../assets/top-logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-intro p-12 flex gap-12"},[_c('div',{staticClass:"flex-1"},[_c('p',[_vm._v("Increase customer engagement")]),_c('p',{staticClass:"mt-2 text-sm"},[_vm._v(" Drip feed our customer with useful guidance ")])]),_c('div',{staticClass:"flex-1"},[_c('p',[_vm._v("Enhance customer satisfaction")]),_c('p',{staticClass:"mt-2 text-sm"},[_vm._v("Help your customer use your product better")])]),_c('div',{staticClass:"flex-1"},[_c('p',[_vm._v("Strengthen customer relationship")]),_c('p',{staticClass:"mt-2 text-sm"},[_vm._v("Extend customer connection with care")])]),_c('div',{staticClass:"flex-1"},[_c('p',[_vm._v("Boost customer value")]),_c('p',{staticClass:"mt-2 text-sm"},[_vm._v(" Create opportunities for repeat, cross and referral sales ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guide-content p-12"},[_c('p',{staticClass:"text-center mt-6 text-5xl"},[_vm._v(" Guide your customer via curated emails ")]),_c('div',{staticClass:"guide-container flex justify-around items-center"},[_c('div',{staticClass:"guide-item"},[_c('img',{staticClass:"icon mb-3",attrs:{"src":require("../../assets/email.png")}}),_c('p',{staticClass:"guide-title"},[_vm._v("Proactive delivery")]),_c('p',{staticClass:"guide-desc"},[_vm._v(" Send planned user guide emails proactively to your customers ")])]),_c('div',{staticClass:"guide-item"},[_c('img',{staticClass:"icon mb-3",attrs:{"src":require("../../assets/drip.png")}}),_c('p',{staticClass:"guide-title"},[_vm._v("Drip Feeding")]),_c('p',{staticClass:"guide-desc"},[_vm._v(" Split your guidance content into small batches for continuous engagement ")])]),_c('div',{staticClass:"guide-item"},[_c('img',{staticClass:"icon mb-3",attrs:{"src":require("../../assets/content.png")}}),_c('p',{staticClass:"guide-title"},[_vm._v("Content rich")]),_c('p',{staticClass:"guide-desc"},[_vm._v(" Support rich information in emails for better tips and guidance ")])]),_c('div',{staticClass:"guide-item"},[_c('img',{staticClass:"icon mb-3",attrs:{"src":require("../../assets/data.png")}}),_c('p',{staticClass:"guide-title"},[_vm._v("Data driven value")]),_c('p',{staticClass:"guide-desc"},[_vm._v(" Track your customer reactions to discover new opportunities ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-guides flex items-center justify-center"},[_c('p',{staticClass:"guide-text"},[_vm._v(" User guides should not stay as a dictionary for customers to look up.They can be engaging contents that boost customer experiences. ")]),_c('div',{staticClass:"mask"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-plan p-12"},[_c('p',{staticClass:"user-plan-title"},[_vm._v("Lower cost, more satisfaction")]),_c('div',{staticClass:"user-plan-container flex items-center justify-center gap-8"},[_c('div',{staticClass:"user-plan-item"},[_c('p',[_vm._v("Free Trial")]),_c('p',{staticClass:"price"},[_vm._v("Free")]),_c('p',[_vm._v("Only 1 User")]),_c('p',[_vm._v("Unlimited Queries")]),_c('p',[_vm._v("Full Statistic")]),_c('div',{staticClass:"pay-btn"},[_vm._v("Select Plan")])]),_c('div',{staticClass:"user-plan-item"},[_c('p',[_vm._v("Standard Subscription")]),_c('p',{staticClass:"price"},[_vm._v("$9")]),_c('p',[_vm._v("Some Services")]),_c('p',[_vm._v("Some Services")]),_c('p',[_vm._v("Some Services")]),_c('div',{staticClass:"pay-btn"},[_vm._v("Select Plan")])]),_c('div',{staticClass:"user-plan-item"},[_c('p',[_vm._v("Premium Subscription")]),_c('p',{staticClass:"price"},[_vm._v("$18")]),_c('p',[_vm._v("Some Services")]),_c('p',[_vm._v("Some Services")]),_c('p',[_vm._v("Some Services")]),_c('div',{staticClass:"pay-btn"},[_vm._v("Select Plan")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer p-12"},[_c('div',{staticClass:"flex flex-col items-center border-b border-solid border-white"},[_c('img',{staticClass:"top-logo",attrs:{"src":require("../../assets/top-logo.png")}}),_c('p',{staticClass:"mt-8"},[_vm._v("Create a better customer journey")]),_c('p',{staticClass:"mb-12"},[_vm._v("By using Journei's digital User Guide solution")])]),_c('p',{staticClass:"text-center mt-8"},[_vm._v("Copy right 2024")])])
}]

export { render, staticRenderFns }