<template>
  <div class="journey-detail-container px-12 py-6">
    <div class="flex items-center">
      <i class="el-icon-arrow-left mr-2 cursor-pointer" @click="goBack"></i>
      <p>Design your journey</p>
    </div>
    <div class="mt-6">
      <el-input
        class="input"
        v-model="bookForm.name"
        @blur="handleChangeName"
      />
      <el-tabs v-model="activeTab">
        <el-tab-pane label="Engagements" name="Engagements">
          <div class="p-2 flex flex-col gap-3">
            <Engagement
              v-for="(item, index) in tableData"
              :key="index"
              :data="item"
              :deletable="true"
              @edit="goDetail(item)"
              @delete="del(item)"
            />
            <div class="add-journey" @click="goDetail()">
              + Add an engagement in your journey
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Introduction" name="Introduction">
          <div class="p-2">
            <EngagementIntro :data="bookForm" @update="fetchData()" /></div
        ></el-tab-pane>
        <el-tab-pane label="Subscribers" name="subscribe-list">
          <div class="p-2">
            <SubscribeList :book_id="book_id"></SubscribeList>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Settings" name="Settings">
          <Settings :data="bookForm" @update="fetchData()" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import {
  getBookContents,
  updateBookContent,
  updateBook,
  removeBookContent,
  getBook,
} from "../../api/index.js";
import { getUserId } from "../../utils/store.js";
import Engagement from "../../components/Engagement";
import EngagementIntro from "../../components/EngagementIntro";
import Settings from "../../components/Settings";
import SubscribeList from "../../components/SubscribeList";
export default {
  components: {
    Engagement,
    EngagementIntro,
    Settings,
    SubscribeList,
  },
  data() {
    return {
      book_id: this.$route.query.bookId,
      tableData: [],
      form: {},
      show: false,
      activeTab: "Engagements",
      bookForm: {
        name: "",
        intro: "",
        open_status: "",
        secrecy: "",
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    goBack() {
      this.$router.push({
        path: "/journeiList",
      });
    },
    fetchData() {
      getBook({
        id: this.$route.query.bookId,
      }).then((res) => {
        this.bookForm = res.data.data;
        if (this.bookForm.appointed_day) {
          this.bookForm.appointed_day = this.bookForm.appointed_day * 1000;
        }
        console.log(res.data.data);
      });
      getBookContents({
        book_id: this.$route.query.bookId,
      }).then((res) => {
        this.tableData = res.data.data.sort(
          (a, b) =>
            100 * Number(a.month) +
            10 * Number(a.week) +
            Number(a.day) -
            (100 * Number(b.month) + 10 * Number(b.week) + Number(b.day))
        );
      });
    },
    submitBook() {
      updateBook({
        creater_id: getUserId(),
        id: this.$route.query.bookId,
        name: this.bookForm.name,
        intro: this.bookForm.intro,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("修改成功");
          this.fetchData();
        }
      });
    },
    goDetail(item) {
      this.$router.push({
        path: "/engagementDetail",
        query: item
          ? {
              id: item._id.$id,
              bookId: this.$route.query.bookId,
            }
          : { bookId: this.$route.query.bookId },
      });
    },
    handleChangeName() {
      updateBook({
        creater_id: getUserId(),
        id: this.$route.query.bookId,
        name: this.bookForm.name,
        intro: this.bookForm.intro,
        open_status: !!this.bookForm.open_status,
        secrecy: this.bookForm.secrecy || "",
        cover_pic: this.bookForm.cover_pic || "",
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("Update Successfully.");
          this.fetchData();
        }
      });
    },
    submit() {
      updateBookContent({
        creater_id: getUserId(),
        book_id: this.$route.query.bookId,
        id: this.form.id,
        name: this.form.name,
        intro: this.form.intro,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("操作成功");
          this.fetchData();
          this.show = false;
        }
      });
    },
    del(row) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeBookContent({
            id: row._id.$id,
          }).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("删除成功");
              this.fetchData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.journey-detail-container {
  font-family: "Lato";
}
.add-journey {
  color: #27a6f9;
  border: 1px dashed #27a6f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 88px;
  cursor: pointer;
  font-size: 16px;
}
.input {
  & /deep/ .el-input__inner {
    border: none;
    padding: 0;
    font-family: "Lota";
    font-size: 24px;
    color: black;
    &::placeholder {
      font-family: "Lota";
      font-size: 24px;
      font-style: italic;
    }
  }
}
</style>
