<template>
  <div class="engagement-wrapper px-12 py-6">
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <i class="el-icon-arrow-left mr-2 cursor-pointer" @click="goBack"></i>
        <p style="color: #666">Design your journey - Design your engagement</p>
      </div>
      <div class="edit-btn" @click="submit">
        {{ $route.query.id ? "Save" : "Add" }}
      </div>
    </div>
    <div>
      <div class="pb-1 mt-8">
        <el-input
          class="input"
          v-model="form.name"
          placeholder="Type a name for your new journey here..."
        />
      </div>
      <div class="pb-1 mb-2">
        <el-input
          class="intro-input"
          v-model="form.intro"
          placeholder="One sentence short introduction for this engagement here.... (CLICK to edit)"
        />
      </div>
      <div class="flex mb-4">
        <div>Engage customers</div>
        <el-radio-group v-model="timeType" style="margin-left: 30px">
          <el-radio label="1">
            <!-- <div class="flex items-center mb-4"> -->            
            <el-input v-model="month" class="num-input" />
            <span class="num-text">month(s)</span>
            <el-input v-model="week" class="num-input" />
            <span class="num-text">week(s)</span>
            <el-input v-model="day" class="num-input" />
            <span class="num-text mr-2">day(s)</span>
            <el-select v-model="time_compare" placeholder="Select" style="width: 100px;">
              <el-option label="After" value="after"></el-option>
              <el-option label="Before" value="before"></el-option>
            </el-select>
            <el-select v-model="sinceDate" placeholder="Select date" style="margin-left: 10px;">
              <el-option label="the date of onboarding" value="onboarding"></el-option>
              <el-option label="an expected beginning date" value="expected_beginning_date"></el-option>
              <el-option label="an expected ending date" value="expected_ending_date"></el-option>
            </el-select>
            <!-- </div> -->
          </el-radio>
          <el-radio label="2">

            on a designated date and time (UTC)
            <el-date-picker type="datetime" v-model="designTime"></el-date-picker>

          </el-radio>
          <!-- <el-radio label="3">
            <el-input v-model="month1" class="num-input" />
            <span class="num-text">month(s)</span>
            <el-input v-model="week1" class="num-input" />
            <span class="num-text">week(s)</span>
            <el-input v-model="day1" class="num-input" />
            <span class="num-text mr-2">day(s)</span>
            after {{ appointed_day }}
          </el-radio> -->
        </el-radio-group>
      </div>
      <quill-editor
        class="editor"
        v-model="emailContent"
        :options="editorOption"
      >
      </quill-editor>
    </div>
  </div>
</template>

<script>
import { getBookContent, updateBookContent } from "../../api/index.js";
import { getUserId } from "../../utils/store.js";
export default {
  data() {
    return {
      form: {
        name: "",
        intro: "",
      },
      month: 0,
      week: 0,
      day: 0,
      month1: 0,
      week1: 0,
      day1: 0,
      emailContent: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
      timeType: "1",
      designTime: this.$dayjs().unix() * 1000,
      sinceDate: 'onboarding',
      time_compare: 'after',

      appointed_day: "",

    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    fetchData() {
      if (!this.$route.query.id) return;
      getBookContent({
        id: this.$route.query.id,
      }).then((res) => {
        this.form = res.data.data;
        this.emailContent = res.data.data.email_content;
        this.month = res.data.data.month || 0;
        this.week = res.data.data.week || 0;
        this.day = res.data.data.day || 0;
        this.timeType = res.data.data.timeType || "1";

        this.designTime = res.data.data.designTime * 1000 || this.$dayjs().unix() * 1000;
        this.sinceDate = res.data.data.sinceDate || 'onboarding';
        this.time_compare = res.data.data.time_compare || 'after';

        this.month1 = res.data.data.appointed_day_month || 0;
        this.week1 = res.data.data.appointed_day_week || 0;
        this.day1 = res.data.data.appointed_day_day || 0;
        this.designTime =
          res.data.data.designTime * 1000 || this.$dayjs().unix() * 1000;

        if (res.data.data.book_info && res.data.data.book_info.appointed_day) {
          this.appointed_day = this.$dayjs(
            res.data.data.book_info.appointed_day * 1000
          ).format("YYYY-MM-DD");
        }

      });
    },
    submit() {
      const regx = /^\+?[1-9][0-9]*$/;

      if (this.timeType == "1") {
        if (!this.month && !this.week && !this.day) {
          this.$message.warning("Please input engage time");
          return;
        }

        if ([this.month, this.week, this.day].every((item) => item === "0")) {
          this.$message.warning("Please input engage time");
          return;
        }

        if (this.month && this.month !== "0" && !regx.test(this.month)) {
          this.$message.warning("Please input correctly formatted months");
          return;
        }

        if (this.week && this.week !== "0" && !regx.test(this.week)) {
          this.$message.warning("Please input correctly formatted weeks");
          return;
        }

        if (this.day && this.day !== "0" && !regx.test(this.day)) {
          this.$message.warning("Please input correctly formatted days");
          return;
        }
      } else if (this.timeType == "3") {
        if (!this.month1 && !this.week1 && !this.day1) {
          this.$message.warning("Please input engage time");
          return;
        }

        if (
          [this.month1, this.week1, this.day1].every((item) => item === "0")
        ) {
          this.$message.warning("Please input engage time");
          return;
        }

        if (this.month1 && this.month1 !== "0" && !regx.test(this.month1)) {
          this.$message.warning("Please input correctly formatted months");
          return;
        }

        if (this.week1 && this.week1 !== "0" && !regx.test(this.week1)) {
          this.$message.warning("Please input correctly formatted weeks");
          return;
        }

        if (this.day1 && this.day1 !== "0" && !regx.test(this.day1)) {
          this.$message.warning("Please input correctly formatted days");
          return;
        }
      } else {
        if (this.designTime == "") {
          this.$message.warning("Please select datetime");
          return;
        }
      }
      if (this.timeType == "3" && !this.appointed_day) {
        this.$message.warning("Please select appointed day");
        return;
      }

      if (false) {
        console.log("date :" + this.designTime);
        console.log("date :" + this.$dayjs(this.designTime).unix());
        return;
      }
      updateBookContent({
        creater_id: getUserId(),
        book_id: this.$route.query.bookId,
        id: this.$route.query.id || "",
        name: this.form.name,
        intro: this.form.intro,
        email_content: this.emailContent,
        month: this.month,
        week: this.week,
        day: this.day,
        timeType: this.timeType,
        designTime: this.$dayjs(this.designTime).unix(),

        sinceDate: this.sinceDate,
        time_compare: this.time_compare,

        appointed_day_month: this.month1,
        appointed_day_week: this.week1,
        appointed_day_day: this.day1,

      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("Successfully!");
          if (this.$route.query.id) {
            this.fetchData();
          } else {
            this.$router.back();
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-radio {
  width: 100%;
  margin-bottom: 20px;
}

.input {
  & /deep/ .el-input__inner {
    border: none;
    padding: 0;
    font-family: "Lota";
    font-size: 18px;
    color: black;

    &::placeholder {
      font-family: "Lota";
      font-size: 18px;
      font-style: italic;
    }
  }
}

.intro-input {
  & /deep/ .el-input__inner {
    border: none;
    padding: 0;
    font-family: "Lota";
    font-size: 14px;

    &::placeholder {
      font-family: "Lota";
      font-size: 14px;
      font-style: italic;
    }
  }
}

/deep/.editor {
  background-color: #fafafa;
  border-radius: 4px;

  .ql-toolbar.ql-snow {
    border: none;
  }

  .ql-container.ql-snow {
    border: none;
  }
}

.num-input {
  width: 48px;
  margin: 0 12px;
}

.num-text {
  color: #27a6f9;
}

.edit-btn {
  width: 90px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fafafa;
  background-color: #27a6f9;
  border-radius: 4px;
}
</style>
