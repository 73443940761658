<template>
  <div class="engagement-wrapper p-3 flex relative" @click="handleClick">
    <div class="time flex flex-col justify-center" v-if="!data.timeType || data.timeType === '1'">
      
      <p class="flex gap-3" v-if="data.month && data.month !== '0'">
        <span class="num">{{ data.month || 0 }}</span>
        <span class="format">Month{{ data.month > 1 ? "s" : "" }}</span>
      </p>
      <p class="flex gap-3" v-if="data.week && data.week !== '0'">
        <span class="num">{{ data.week || 0 }}</span>
        <span class="format">Week{{ data.week > 1 ? "s" : "" }}</span>
      </p>
      <p class="flex gap-3" v-if="data.day && data.day !== '0'">
        <span class="num">{{ data.day || 0 }}</span>
        <span class="format">Day{{ data.day > 1 ? "s" : "" }}</span>
      </p>
      <p class="after-text text-xs text-black">After</p>
    </div>
    <div v-if="data.timeType === '2'" class="time flex items-center">
      <p class="after-text text-xs text-black">On</p>
      <p>{{ $dayjs(data.designTime * 1000).format("YYYY MMMM DD")}}</p>
    </div>
    <div class="ml-4">
      <p class="name text-xl mb-2">{{ data.name }}</p>
      <p class="intro" v-html="data.intro"></p>
    </div>
    <i
      v-if="deletable"
      class="el-icon-delete absolute delete-icon"
      @click.stop="onDelete"
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    deletable: {
      type: Boolean,
    },
  },
  methods: {
    handleClick() {
      this.$emit("edit");
    },
    onDelete() {
      this.$emit("delete");
    },
  },
};
</script>

<style lang="scss" scoped>
.engagement-wrapper {
  font-family: "Lato";
  border-radius: 4px;
  background: #fefefe;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  .time {
    color: #27a6f9;
    padding-right: 4px;
    border-right: 1px solid #ddd;
    .after-text {
      position: absolute;
      top: 2px;
    }
    .num {
      width: 20px;
      text-align: center;
    }
    .format {
      width: 60px;
    }
  }
  &:hover {
    .delete-icon {
      opacity: 1;
    }
  }
  .intro {
    color: #666;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .delete-icon {
    font-size: 20px;
    color: #999;
    right: 12px;
    bottom: 12px;
    opacity: 0;
  }
}
</style>
