<template>
    <div>
        <el-button size="small" type="primary" @click="doShowBatchAddUserDialog">Add Subscribers by Batch</el-button>
        <el-table :data="dataList" empty-text="No Subscribe Data" style="margin-top: 20px;">
            <el-table-column type="index" prop="index" label="Index" width="120px"></el-table-column>
            <el-table-column prop="user_info.user_email" label="User Email"></el-table-column>
            <!-- <el-table-column prop="user_info.user_name" label="User Name"></el-table-column> -->
        </el-table>
        <el-dialog :visible.sync="showBatchAddDialog" title="Add Subscribers By Batch" width="55%">
            <div>
                <el-input type="textarea" :rows="15" v-model="emails"
                    placeholder="Input emails here. One email per row."></el-input>

                <div style="margin-top: 20px;text-align: right;">
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="showBatchAddDialog = false">Cancel</el-button>
                        <el-button type="primary" @click="confirmAddUsers">Add</el-button>
                    </span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    batchOnboardByEmails,
    getBookOnboardUsers
} from '../../api/index'
export default ({
    name: 'index',
    props: {
        book_id: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dataList: [],
            showBatchAddDialog: false,
            emails: ''
        }
    },
    watch: {
        book_id: {
            handler(newVal, oldVal) {
                this.requestList()
            },
            immediate: true
        }
    },
    methods: {

        confirmAddUsers() {
            if (this.emails == '') {
                this.$message.warning('Please input emails')
                return
            }
            let tempEmails = this.emails.split('\n')
            if (tempEmails.length <= 0) {
                this.$message.warning('Please input emails')
                return
            }
            let formatEmails = []
            for (let i = 0; i < tempEmails.length; i++) {
                let email = tempEmails[i]
                if (email && email.length > 0) {
                    email = email.trim()
                    formatEmails.push(email)
                }
            }
            if (formatEmails.length <= 0) {
                this.$message.warning('Please input emails')
                return
            }
            batchOnboardByEmails(formatEmails, this.book_id).then((res) => {
                let result = res.data.data
                this.$message.success(result)
                this.requestList()
                this.showBatchAddDialog = false
            })
        },

        doShowBatchAddUserDialog() {
            this.emails = ''
            this.showBatchAddDialog = true
        },
        requestList() {
            if (this.book_id && this.book_id != '') {
                getBookOnboardUsers(this.book_id).then((res) => {
                    this.dataList = res.data.data
                })
            }
        }
    }
})
</script>
