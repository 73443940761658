<template>
  <div class="home">
    <div class="top-container relative">
      <div class="flex p-12 justify-between z-10 relative">
        <div>
          <img class="top-logo" src="../../assets/top-logo.png" />
        </div>
        <div class="hover:underline cursor-pointer" @click="goLogin">
          Sign in
        </div>
      </div>
      <div class="relative z-10 mt-12">
        <p class="text-center top-title">Create a better customer journey</p>
        <p class="text-center top-sub-title">
          By using Journei's digital User Guide solution
        </p>
        <div class="flex justify-center items-center mt-24 gap-12">
          <div class="btn start-btn" @click="goLogin">Start</div>
          <div class="btn more-btn">Learn More</div>
        </div>
      </div>
      <div class="top-intro p-12 flex gap-12">
        <div class="flex-1">
          <p>Increase customer engagement</p>
          <p class="mt-2 text-sm">
            Drip feed our customer with useful guidance
          </p>
        </div>
        <div class="flex-1">
          <p>Enhance customer satisfaction</p>
          <p class="mt-2 text-sm">Help your customer use your product better</p>
        </div>
        <div class="flex-1">
          <p>Strengthen customer relationship</p>
          <p class="mt-2 text-sm">Extend customer connection with care</p>
        </div>
        <div class="flex-1">
          <p>Boost customer value</p>
          <p class="mt-2 text-sm">
            Create opportunities for repeat, cross and referral sales
          </p>
        </div>
      </div>
    </div>
    <div class="guide-content p-12">
      <p class="text-center mt-6 text-5xl">
        Guide your customer via curated emails
      </p>
      <div class="guide-container flex justify-around items-center">
        <div class="guide-item">
          <img src="../../assets/email.png" class="icon mb-3" />
          <p class="guide-title">Proactive delivery</p>
          <p class="guide-desc">
            Send planned user guide emails proactively to your customers
          </p>
        </div>
        <div class="guide-item">
          <img src="../../assets/drip.png" class="icon mb-3" />
          <p class="guide-title">Drip Feeding</p>
          <p class="guide-desc">
            Split your guidance content into small batches for continuous
            engagement
          </p>
        </div>
        <div class="guide-item">
          <img src="../../assets/content.png" class="icon mb-3" />
          <p class="guide-title">Content rich</p>
          <p class="guide-desc">
            Support rich information in emails for better tips and guidance
          </p>
        </div>
        <div class="guide-item">
          <img src="../../assets/data.png" class="icon mb-3" />
          <p class="guide-title">Data driven value</p>
          <p class="guide-desc">
            Track your customer reactions to discover new opportunities
          </p>
        </div>
      </div>
    </div>
    <div class="user-guides flex items-center justify-center">
      <p class="guide-text">
        User guides should not stay as a dictionary for customers to look
        up.They can be engaging contents that boost customer experiences.
      </p>
      <div class="mask"></div>
    </div>
    <div class="user-plan p-12">
      <p class="user-plan-title">Lower cost, more satisfaction</p>
      <div class="user-plan-container flex items-center justify-center gap-8">
        <div class="user-plan-item">
          <p>Free Trial</p>
          <p class="price">Free</p>
          <p>Only 1 User</p>
          <p>Unlimited Queries</p>
          <p>Full Statistic</p>
          <div class="pay-btn">Select Plan</div>
        </div>
        <div class="user-plan-item">
          <p>Standard Subscription</p>
          <p class="price">$9</p>
          <p>Some Services</p>
          <p>Some Services</p>
          <p>Some Services</p>
          <div class="pay-btn">Select Plan</div>
        </div>
        <div class="user-plan-item">
          <p>Premium Subscription</p>
          <p class="price">$18</p>
          <p>Some Services</p>
          <p>Some Services</p>
          <p>Some Services</p>
          <div class="pay-btn">Select Plan</div>
        </div>
      </div>
    </div>
    <div class="footer p-12">
      <div class="flex flex-col items-center border-b border-solid border-white">
        <img class="top-logo" src="../../assets/top-logo.png" />
        <p class="mt-8">Create a better customer journey</p>
        <p class="mb-12">By using Journei's digital User Guide solution</p>
      </div>
      <p class="text-center mt-8">Copy right 2024</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    goLogin() {
      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  min-height: 100vh;
  min-width: 960px;
  position: relative;
  font-family: 'Segoe UI';
}
.top-container {
  width: 100%;
  background-image: url("../../assets/top.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 860px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  .top-logo {
    width: 105px;
  }
  .top-intro {
    width: 100%;
    position: absolute;
    bottom: 24px;
    z-index: 10;
    border-top: 1px solid #fff;
  }
  .top-title {
    font-size: 70px;
  }
  .top-sub-title {
    font-size: 22px;
  }
  .btn {
    width: 196px;
    height: 62px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 20px;
    &:hover {
      transform: scale(1.05);
      transition: all 0.2s ease-in;
    }
  }
  .start-btn {
    background: linear-gradient(90deg, #27b0f9 0%, #275ef9 100%);
  }
  .more-btn {
    border: 2px solid #fff;
  }
}
.guide-container {
  margin-top: 96px;
  margin-bottom: 48px;
  .guide-item {
    width: 256px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .icon {
      width: 48px;
      height: 48px;
    }
    .guide-title {
      font-size: 24px;
      margin-bottom: 12px;
    }
    .guide-desc {
      color: #606060;
      font-size: 16px;
      min-height: 72px;
    }
  }
}
.user-guides {
  width: 100%;
  height: 580px;
  background-image: url("../../assets/guide.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  font-size: 50px;
  position: relative;
  .guide-text {
    text-align: center;
    width: 900px;
    z-index: 2;
  }
  .mask {
    background: linear-gradient(90deg, #27b0f9 0%, #275ef9 100%);
    opacity: 0.7;
    width: 100%;
    height: 580px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
.user-plan {
  &-title {
    font-size: 68px;
    margin: 48px 0;
    text-align: center;
  }
  &-container {
    .user-plan-item {
      width: 260px;
      color: #202020;
      font-size: 16px;
      text-align: center;
      border: 2px solid #f7f7f7;
      border-radius: 16px;
      padding: 24px 0;
      .price {
        font-size: 50px;
        margin: 16px 0;
      }
      .pay-btn {
        width: 160px;
        margin: 32px 50px 24px;
        height: 50px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 18px;
        background: linear-gradient(90deg, #27b0f9 0%, #275ef9 100%);
        color: white;
        &:hover {
          transform: scale(1.05);
          transition: all 0.2s ease-in;
        }
      }
    }
  }
}
.footer {
  background: black;
  color: white;
  .top-logo {
    width: 105px;
  }
}
</style>
