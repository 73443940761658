<template>
  <div class="flex">
    <div class="flex-1 pr-8 border-r border-solid" style="border-color: #ddd">
      <el-input
        class="input"
        v-model="data.intro"
        type="textarea"
        autosize
        placeholder="Input the introduction text for your journey here. If it is an open journey, people will see this introduction. Otherwise, the introduction is for your and your team’s internal reference only."
        @blur="handleChangeIntro"
      />
    </div>
    <div class="pl-8">
      <el-upload
        class="avatar-uploader flex items-center justify-center"
        action="#"
        :limit="1"
        :before-upload="beforeAttachUpload"
        :show-file-list="false"
      >
        <img v-if="data.cover_pic" :src="data.cover_pic" class="avatar" />
        <div v-else>
          <i class="el-icon-plus avatar-uploader-icon"></i>
          <p class="tips">Upload a cover for your journey</p>
        </div>
      </el-upload>
    </div>
  </div>
</template>

<script>
import { updateBook, getQiniuToken } from "../../api/index";
import { getUserId } from "../../utils/store.js";
import * as qiniu from "qiniu-js";
export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      imageUrl: "",
    };
  },
  methods: {
    async handleChangeIntro() {
      try {
        const res = await updateBook({
          creater_id: getUserId(),
          id: this.data._id.$id,
          name: this.data.name,
          intro: this.data.intro,
          open_status: !!this.data.open_status,
          secrecy: this.data.secrecy || "",
          cover_pic: this.data.cover_pic || "",
        });
        if (res.data.code === 0) {
          this.$message.success("Successful.");
          this.$emit("update");
        }
      } catch (error) {}
    },
    async beforeAttachUpload(file) {
      const result = await this.getQiniuToken();
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        );
        const observer = {
          complete: async (res) => {
            const url = "http://mainbucket.reachable-edu.com/" + res.key;
            try {
              const res = await updateBook({
                creater_id: getUserId(),
                id: this.data._id.$id,
                name: this.data.name,
                intro: this.data.intro,
                open_status: !!this.data.open_status,
                secrecy: this.data.secrecy || "",
                cover_pic: url,
              });
              if (res.data.code === 0) {
                this.$message.success("Successful.");
                this.$emit("update");
              }
            } catch (error) {
              console.log(error);
            }
          },
        };
        this.observable.subscribe(observer);
      }
    },
    async getQiniuToken() {
      return new Promise((resolve) => {
        getQiniuToken().then((res) => {
          resolve(res);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader {
  width: 240px;
  height: 160px;
  border-radius: 4px;
  border: 1px dashed #27a6f9;
  &-icon {
    color: #27a6f9;
  }
  .avatar {
    width: 240px;
    height: 160px;
    border-radius: 4px;
    object-fit: cover;
  }
  .tips {
    color: #27a6f9;
    width: 180px;
  }
}
.input {
  & /deep/ .el-textarea__inner {
    border: none;
    padding: 0;
    font-family: "Lota";
    font-size: 16px;
    color: black;
    &::placeholder {
      font-family: "Lota";
      font-size: 16px;
      font-style: italic;
    }
  }
}
</style>
