<template>
  <div class="journey-detail-container p-12">
    <div class="flex">
      <div class="flex flex-1">
        <img
          v-if="bookForm.cover_pic"
          class="cover mr-8"
          :src="bookForm.cover_pic"
        />
        <div
          v-else
          class="cover mr-8 flex items-center justify-center"
          style="background-color: #f8f8f8"
        >
          <img
            src="../../assets/empty-cover.png"
            style="width: 150px; height: 50px"
          />
        </div>
        <div>
          <p class="text-2xl">{{ bookForm.name }}</p>
          <div class="flex text-base gap-12 mt-4">
            <p>
              Engagements:<span class="ml-3" style="color: #27a6f9">{{
                tableData.length
              }}</span>
            </p>
            <p>
              Customers onboard:<span class="ml-3" style="color: #27a6f9">{{
                bookForm.onboard_count
              }}</span>
            </p>
          </div>
          <div class="flex items-center mt-4">
            <p class="italic">Created by:</p>
            <div class="flex items-center">
              <el-avatar
                :src="creatorInfo.creater_avatar"
                class="avatar"
              ></el-avatar>
              <div>
                <p>{{ creatorInfo.creater_name }}</p>
                <p class="text-xs" style="color: #666">
                  {{ creatorInfo.creater_signature }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn" @click="handleBoard" v-if="!bookForm.isOnboard">
        Onboard
      </div>
      <div class="btn" style="background-color: #999" v-else>Boarded</div>
    </div>
    <p class="intro">{{ bookForm.intro }}</p>
    <div class="p-2 flex flex-col gap-3">
      <Engagement
        v-for="(item, index) in engagementList"
        :key="index"
        :data="item"
      />
      <div class="add-journey" v-if="bookForm.secrecy === 'only'">
        More contents kept secret by the creator to surprise you along the
        journey.
      </div>
    </div>
  </div>
</template>

<script>
import Engagement from "../../components/Engagement";
import {
  getBookContents,
  getBookUser,
  getBookContentsUser,
  confirmOnboard,
} from "../../api/index";
import { getUserId } from "../../utils/store";
export default {
  components: {
    Engagement,
  },
  data() {
    return {
      tableData: [],
      form: {},
      creatorInfo: {
        creater_avatar: "",
        creater_name: "",
        creater_signature: "",
      },
      show: false,
      activeTab: "Engagements",
      bookForm: {
        name: "",
        intro: "",
        open_status: "",
        secrecy: "",
        cover_pic: "",
        isOnboard: false,
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    engagementList() {
      return this.bookForm.secrecy === "only"
        ? this.tableData.slice(0, 5)
        : this.tableData;
    },
  },
  methods: {
    fetchData() {
      // getBookContents({
      //   book_id: this.$route.query.bookId,
      // }).then((res) => {
      //   this.tableData = res.data.data;
      // });
      getBookUser({
        book_id: this.$route.query.bookId,
        user_id: getUserId(),
      }).then((res) => {
        this.bookForm = res.data.data;
        if (res.data.data && res.data.data.creater_info) {
          this.creatorInfo = res.data.data.creater_info;
        }
      });
      getBookContentsUser({
        book_id: this.$route.query.bookId,
        user_id: getUserId(),
      }).then((res) => {
        this.tableData = res.data.data;
      });
    },
    async handleBoard() {
      try {
        const res = await confirmOnboard({
          book_id: this.$route.query.bookId,
          user_id: getUserId(),
        });
        if (res.data.code === 0) {
          this.$message.success("Onboard successfully.");
          this.fetchData();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.journey-detail-container {
  font-family: "Lato";
  .btn {
    width: 90px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fafafa;
    background-color: #27a6f9;
    border-radius: 4px;
  }
  .cover {
    width: 240px;
    height: 160px;
    border-radius: 8px;
    background-color: #ddd;
    object-fit: cover;
  }
  .avatar {
    margin-left: 24px;
    margin-right: 12px;
  }
  .intro {
    color: #666;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    margin: 32px 0;
  }
  .add-journey {
    color: #27a6f9;
    border: 1px dashed #27a6f9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 64px;
    cursor: pointer;
    font-size: 16px;
  }
}
</style>
