<template>
  <div class="setting-wrapper">
    <div class="my-4">
      <div class="flex items-center justify-between mb-2">
        <p>Set as an OPEN journey</p>
        <el-switch v-model="data.open_status" @change="onChange"> </el-switch>
      </div>
      <p class="desc">
        An open Journey will be public on Journei website to general audience
        who can onboard the journey as they want.
      </p>
    </div>
    <div class="my-4">
      <div class="flex items-center justify-between mb-2">
        <p>Secrecy of engagements</p>
        <el-select
          v-model="data.secrecy"
          placeholder="Please select"
          @change="onChange"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <p class="desc">
        Choose whether you want to keep your engagements partially secret when
        people browse and after they onboard. Engagements kept secret will not
        be revealed to customers until they are delivered.
      </p>
    </div>
    <div class="my-4">
      <div class="flex items-center justify-between mb-2">
        <p>Set an appointed day</p>
        <el-date-picker
          :clearable="false"
          v-model="data.appointed_day"
          @change="onChange"
        >
        </el-date-picker>
      </div>
    </div>

    <div class="my-4">
      <div style="display: flex; align-items: center" class="mb-2">
        <p>Sender Email</p>
        <el-input
          v-model="data.notify_sender_email"
          style="width: 55%; margin: 0px 20px"
        ></el-input>
        <el-button type="primary" size="small" @click="saveEmail"
          >Save</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { updateBook, saveBookSenderEmail } from "../../api/index";
import { getUserId } from "../../utils/store.js";
export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      options: [
        {
          value: "only",
          label: "Show the first 5 only",
        },
        {
          value: "all",
          label: "Show all",
        },
      ],
    };
  },
  methods: {
    saveEmail() {
      if (!this.data.notify_sender_email) {
        this.$message.warning("Please input sender email");
        return;
      }
      saveBookSenderEmail({
        id: this.data._id.$id,
        notify_sender_email: this.data.notify_sender_email.trim(),
      }).then((res) => {
        let result = res.data;
        if (result.code == 0) {
          this.$message.success("Save success~");
          this.$emit("update");
        } else {
          this.$message.error(result.msg);
        }
      });
    },

    async onChange() {
      try {
        const res = await updateBook({
          creater_id: getUserId(),
          id: this.data._id.$id,
          name: this.data.name,
          intro: this.data.intro,
          open_status: !!this.data.open_status,
          secrecy: this.data.secrecy || "",
          cover_pic: this.data.cover_pic || "",
          appointed_day:
            new Date(this.data.appointed_day).getTime() / 1000 || "",
        });
        if (res.data.code === 0) {
          this.$message.success("Successful.");
          this.$emit("update");
        }
      } catch (error) {}
    },
  },
};
</script>

<style lang="scss" scoped>
.setting-wrapper {
  font-family: "Lato";
  width: 420px;
  .desc {
    font-size: 14px;
    color: #666;
  }
}
</style>
