<template>
  <div class="verify-wrapper">
    <div class="verify-box flex flex-col items-center">
      <img class="logo" src="../../assets/loginLogo.png" />
      <div
        v-if="!isSendCode"
        class="verify-container mt-4 flex flex-col items-center"
      >
        <el-radio-group
          v-model="userType"
          class="w-full flex justify-between pr-8 mb-4"
        >
          <el-radio label="creator">Login as creator</el-radio>
          <el-radio label="user">Login as audience</el-radio>
        </el-radio-group>
        <p class="mb-6">
          {{
            userType === "creator"
              ? "Start to create content journeys at Journei that your audience or customers can onboard and enjoy."
              : "Start to onboard journeys at Journei that fulfill your personal learning and life."
          }}
        </p>
        <el-input class="input mb-6" v-model="email" placeholder="Your Email" />
        <div
          class="login-btn py-2 px-4 flex items-center justify-center"
          @click="handleLogin"
        >
          LOGIN
        </div>
        <p class="tips mt-6">
          An OTP will be sent your email for identity verification
        </p>
      </div>
      <div
        v-else
        class="v-code-wrapper verify-container mt-4 flex flex-col items-center"
      >
        <p class="mb-6">
          Please go to your email box and input the OTP you receive.
        </p>
        <div class="code-input-box mb-6">
          <div class="v-code">
            <input
              ref="vcode"
              id="vcode"
              maxlength="4"
              v-model="vCode"
              @focus="focused = true"
              @blur="focused = false"
              @keydown="forbidMoveCursor($event)"
              :autofocus="false"
            />
            <label
              for="vcode"
              class="line"
              v-for="(item, index) in codeLength"
              :key="index"
              :class="{ animated: focused && cursorIndex === index }"
              v-text="codeArr[index]"
            >
            </label>
          </div>
        </div>
        <div
          class="login-btn py-2 px-4 flex items-center justify-center"
          @click="handleVerify"
        >
          VERIFY
        </div>
        <p class="tips mt-6">
          You will be directed to the user panel after successful verification.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  setUserAvatar,
  setUserId,
  setUserName,
  setUserSignature,
  setUserType,
} from "../../utils/store";
import { createLoginPinCode, verifyLogin } from "../../api/index.js";
export default {
  data() {
    return {
      email: "",
      vCode: "",
      codeLength: 4,
      focused: false,
      isSendCode: false,
      userType: "creator",
    };
  },
  computed: {
    codeArr() {
      return this.vCode.split("");
    },
    cursorIndex() {
      return this.vCode.length;
    },
  },
  watch: {
    vCode(newVal, oldVal) {
      const reg = /^[A-Za-z0-9]+$/;
      if (reg.test(newVal)) {
        this.vCode = newVal;
      } else {
        this.vCode = newVal.replace(/[^\w]/g, "");
      }
      if (newVal.length === 4) {
        this.handleVerify();
      }
    },
  },
  methods: {
    forbidMoveCursor(event) {
      if (event.keyCode >= 35 && event.keyCode <= 40) {
        event.preventDefault();
      }
    },
    async handleLogin() {
      if (!this.email) {
        this.$message.warning("Please input email");
        return;
      }
      try {
        const res = await createLoginPinCode({
          email: this.email,
          type: this.userType === "creator" ? "creater" : "user",
        });
        if (res.data.status === "success") {
          this.$message.success(res.data.msg || "OTP sent successfully");
          this.isSendCode = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleVerify() {
      if (!this.vCode) {
        this.$message.warning("Please input OTP code");
        return;
      }
      try {
        const res = await verifyLogin({
          email: this.email,
          pin: this.vCode,
          type: this.userType === "creator" ? "creater" : "user",
        });
        if (res.data.status === "success") {
          this.$message({
            message: "Login successfully.",
            type: "success",
            duration: 1500,
          });
          setUserId(res.data.data._id.$id);
          setUserName(
            this.userType === "creator"
              ? res.data.data.creater_name
              : res.data.data.user_name
          );
          setUserAvatar(
            this.userType === "creator"
              ? res.data.data.creater_avatar
              : res.data.data.user_avatar
          );
          setUserSignature(
            this.userType === "creator"
              ? res.data.data.creater_signature
              : res.data.data.user_signature
          );
          setUserType(this.userType === "creator" ? "creater" : "user");
          this.$router.push(
            this.userType === "creator" ? "/journeiList" : "/user/book"
          );
        } else {
          this.$message({
            message: "OTP Incorrect",
            type: "error",
            duration: 1500,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.verify-wrapper {
  background-color: #fafafa;
  width: 100vw;
  height: 100vh;
  .input {
    & /deep/ .el-input__inner {
      border: none;
      font-family: "Lota";
      background-color: #fafafa;
      &::placeholder {
        font-family: "Lota";
      }
    }
  }
  .verify-box {
    position: absolute;
    top: 160px;
    left: 50%;
    transform: translateX(-50%);
  }
  .logo {
    width: 180px;
    height: 56px;
  }
  .verify-container {
    width: 540px;
    background-color: white;
    padding: 64px 48px;
    border-radius: 12px;
  }
  .login-btn {
    font-family: "Lato";
    font-size: 14px;
    border-radius: 4px;
    background: #27a6f9;
    color: white;
    width: fit-content;
    cursor: pointer;
  }
  .tips {
    color: #666;
    font-size: 12px;
  }
}
.v-code-wrapper {
  background-color: #fff;
  text-align: center;
}

.v-code-wrapper .code-input-box {
  color: #2e2f33;
  font-size: 16px;
}

.v-code-wrapper .code-input-box .v-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 300px;
  margin: 0 auto;
}

.v-code-wrapper .code-input-box .v-code input {
  position: absolute;
  top: -100%;
  left: -666666px;
  opacity: 0;
}

.v-code-wrapper .code-input-box .v-code .line {
  position: relative;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  color: #2e2f33;
  border-radius: 4px;
  background-color: #fafafa;
  cursor: text;
}

.v-code-wrapper .code-input-box .v-code .line::after {
  display: block;
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  bottom: 0;
  height: 1px;
  transform: scaleY(0.5);
  transform-origin: 0 100%;
}

.v-code-wrapper .code-input-box .v-code .line.animated::before {
  display: block;
  position: absolute;
  left: 50%;
  top: 35%;
  width: 1px;
  height: 40%;
  content: "";
  background-color: #2e2f33;
  animation-name: coruscate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

@keyframes coruscate {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
