<template>
  <div class="contentDetail">
    <el-form :model="form">
      <el-form-item label="名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="简介">
        <quill-editor
          class="editor"
          style="margin-top: 40px"
          v-model="form.intro"
          :options="editorOption"
        >
        </quill-editor>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="submit">提交</el-button>
  </div>
</template>

<script>
import { getBookContent, updateBookContent } from "../../api/index.js";
import { getUserId } from "../../utils/store.js";
export default {
  data() {
    return {
      form: {},
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getBookContent({
        id: this.$route.query.id,
      }).then((res) => {
        this.form = res.data.data;
      });
    },
    submit() {
      updateBookContent({
        creater_id: getUserId(),
        book_id: this.form.book_id,
        id: this.$route.query.id,
        name: this.form.name,
        intro: this.form.intro,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("修改成功");
          this.fetchData();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contentDetail {
  width: 100%;
  padding: 16px;
}
</style>